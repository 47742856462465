import type { AuthProvider } from '@aurora/shared-generated/types/graphql-schema-types';
import { AuthMechanism } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  AuthProvidersQuery,
  AuthProvidersQueryVariables
} from '@aurora/shared-generated/types/graphql-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { redirectToMultiAuthClient } from '@aurora/shared-utils/helpers/anonymousUserActions/AnonymousUserActionsHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import IconTypes from '../../../icons';
import useEndUserNextPage from '../../../routes/useEndUserNextPage';
import useEndUserRoutes from '../../../routes/useEndUserRoutes';
import { AuthFlow } from '../../../types/enums';
import Button from '../../common/Button/Button';
import { ButtonVariant } from '../../common/Button/enums';
import { IconSize } from '../../common/Icon/enums';
import Icon from '../../common/Icon/Icon';
import useCommunitySsoProperties from '../../community/useCommunitySsoProperties';
import AuthFlowContext from '../../context/AuthFlowContext/AuthFlowContext';
import TenantContext from '../../context/TenantContext';
import useQueryWithTracing from '../../useQueryWithTracing';
import authProvidersQuery from '../AuthProviders.query.graphql';
import LoginForm from '../LoginForm/LoginForm';
import { useMultiAuthText } from '../UseMultiAuthText/useMultiAuthText';
import localStyle from './MultiAuthLoginForm.module.pcss';

interface Props {
  /**
   * Callback function after login.
   *
   * @callback
   */
  onLogin: () => void;

  /**
   * Whether allow backdoor normal sign-on.
   */
  ssoAllowNormalSignon?: boolean;

  /**
   * Callback function after to set authflow on AuthenticationModal.
   *
   * @callback
   * @param value
   */
  setAuthFlow: (value: AuthFlow) => void;

  /**
   * Callback function after clicking on community sign in button from sign in page.
   *
   * @callback
   */
  onShowLoginForm?: () => void;

  /**
   * Whether to hide the login form modal and show it in the same page for admin-app and enduser-app sign in page.
   */
  hideLoginFormModal?: boolean;
}

/**
 * Displays a multi-auth login form.
 *
 * @author Muttu K Sulikeri
 */
const MultiAuthLoginForm: React.FC<React.PropsWithChildren<Props>> = ({
  onLogin,
  ssoAllowNormalSignon = false,
  setAuthFlow,
  onShowLoginForm,
  hideLoginFormModal = false,
  children
}) => {
  const { router } = useEndUserRoutes();
  const { formatMessage, textLoading } = useMultiAuthText();
  const tenant = useContext(TenantContext);
  const {
    publicConfig: { multiAuthEnabled, multiAuthLocalisation }
  } = tenant;
  const cx = useClassNameMapper(localStyle);
  const { showAuthenticationModal } = useContext(AuthFlowContext);
  const { nextRoute } = useEndUserNextPage(null);
  const { data: ssoProperties } = useCommunitySsoProperties(module);
  // This param specific to oauth authorization code flow
  const nextAuthFlowHandler = router.getUnwrappedQueryParam(EndUserQueryParams.NEXT_AUTH_FLOW);

  const { data: authProvidersData, loading: authProvidersLoading } = useQueryWithTracing<
    AuthProvidersQuery,
    AuthProvidersQueryVariables
  >(module, authProvidersQuery, {
    skip: !multiAuthEnabled
  });

  const enabledAuthProviders = authProvidersData?.authProviders
    ?.filter(authProvider => authProvider.enabled)
    ?.sort((provider1, provider2) => provider1.order - provider2.order);
  const [localAuthProvider, ...rest] = authProvidersData?.authProviders ?? [];
  const authProviderInfo = {
    localAuthProvider: localAuthProvider ?? null,
    availableAuthProvider: [...rest],
    enabledAuthProvider: enabledAuthProviders ?? []
  };

  if (textLoading || authProvidersLoading) {
    return null;
  }

  async function onSignIn(): Promise<void> {
    if (hideLoginFormModal) {
      onShowLoginForm();
    } else {
      showAuthenticationModal(AuthFlow.LOGIN, nextRoute, authProviderInfo);
      setAuthFlow(AuthFlow.LOGIN);
    }
  }

  /**
   * Renders multi-auth options
   * @param authProviders Auth provider list
   */
  function renderMultiAuthOption(authProviders: AuthProvider[]): React.ReactElement {
    const authProviderList = [];
    authProviders.forEach((authProvider: AuthProvider, index) => {
      if (authProvider.enabled) {
        authProviderList[authProvider.order] =
          authProvider.authMechanism === AuthMechanism.Local &&
          authProvider.enabled &&
          authProvider.customMetadata?.showLoginForm ? (
            <React.Fragment key={authProvider.id}>
              <span
                id="lia-login-methods-separator-top"
                className={cx('lia-separator', { 'd-none': index === 0 })}
              >
                {formatMessage('separatorText')}
              </span>
              <div id="lia-login-form">
                <LoginForm onLogin={onLogin}>{children}</LoginForm>
              </div>
              <span
                id="lia-login-methods-separator-bottom"
                className={cx('lia-separator', {
                  'd-none': index === authProviders.length - 1
                })}
              >
                {formatMessage('separatorText')}
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment key={authProvider.id}>
              <Button
                key={authProvider.id}
                variant={ButtonVariant.SECONDARY}
                size="lg"
                className={cx(
                  'd-flex align-items-center justify-content-center lia-g-column-gap-10'
                )}
                onClick={
                  authProvider.authMechanism === AuthMechanism.Local &&
                  !authProvider.customMetadata?.showLoginForm
                    ? onSignIn
                    : () => {
                        redirectToMultiAuthClient(
                          authProvider,
                          tenant,
                          nextAuthFlowHandler || router.path,
                          ssoProperties?.community?.ssoProperties?.ssoQsParam
                        );
                      }
                }
              >
                {authProvider.authMechanism !== AuthMechanism.Local && (
                  <Icon
                    icon={IconTypes.KeyIcon}
                    size={IconSize.PX_16}
                    className={cx('lia-g-svg-current-color')}
                  />
                )}
                {formatMessage(
                  multiAuthLocalisation
                    ? `${authProvider.id}.buttonText.label`
                    : 'authMechanism.buttonText.label',
                  { name: authProvider.name }
                )}
              </Button>
            </React.Fragment>
          );
      }
    });
    return (
      authProviderList.length > 0 && (
        <div
          role="group"
          aria-labelledby="lia-multiauth-login-methods"
          className={cx('d-flex flex-column lia-g-row-gap-20')}
        >
          {authProviderList}
        </div>
      )
    );
  }

  return (
    multiAuthEnabled && !ssoAllowNormalSignon && renderMultiAuthOption([...enabledAuthProviders])
  );
};

export default MultiAuthLoginForm;
